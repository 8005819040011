import React, { ReactElement, useEffect } from 'react';
import './App.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ComponentItem from './components/component-item';
import TopBar from './components/top-bar';
import { FETCH_INTERVAL_MS } from './utils/constants';
import { getComponentHealthzStatus } from './slices/components.slice';
import { useAppDispatch, useAppSelector } from './store';
import { Component } from './model/component.model';

declare global {
    interface Window {
        _env_: {
            RUUTER_API_URL: string;
        };
    }
}

const App = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const components = useAppSelector((state) => state.components.components);
    const requestFailed = useAppSelector((state) => state.components.requestFailed);

    useEffect(() => {
        dispatch(getComponentHealthzStatus());
        setInterval(() => dispatch(getComponentHealthzStatus()), FETCH_INTERVAL_MS);
    }, [dispatch]);

    return (
        <AppStyles>
            <TopBar />
            {!requestFailed ? components.map((module: Component) =>
                <ComponentItem key={module.name} name={module.name} version={module.version}/>) :
                <div className="fetchError">{t('app.requestFailed')}</div>
            }
        </AppStyles>
    );
};

const AppStyles = styled.div`
  .fetchError {
    display: flex;
    flex-direction: row;
    width: 30%;
    margin: 1rem auto;
    justify-content: center;
    font-size: medium;
  }
`;

export default App;

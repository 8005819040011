import React from 'react';
import styled from 'styled-components';
import { COMPONENT_STATUS } from '../utils/constants';

const ComponentItem = (props: { name: string, version: string }): JSX.Element => {
    const { name, version } = props;

    return (
        <ComponentItemStyles>
            <div className="left-container">
                <div className="name">{name}</div>&nbsp;
                {version && <div className="version">{` @ version ${  version}`}</div>}
            </div>
            <div className={version ? 'status ok' : 'status not-ok'}>{version ? COMPONENT_STATUS.OPERATIONAL : COMPONENT_STATUS.NON_OPERATIONAL}</div>
        </ComponentItemStyles>
    );
};

const ComponentItemStyles = styled.div`
  display: flex;
  flex-direction: row;
  border: 1.5px solid #003cff;
  box-shadow: 2px 2px 4px #adc0ff;
  width: 50%;
  height: 3.5rem;
  margin: 0.8rem auto;
  justify-content: space-between;
  align-items: center;
  
  .left-container {
    display: flex;
    flex-direction: row;
    margin: 1rem;
  }
  
  .status {
    margin: 1rem;
  }
  
  .name,
  .version,
  .status {
    font-size: larger;
  }
  
  .ok {
    color: #52a980;
  }
  
  .not-ok {
    color: #FF6130;
  }
`;

export default ComponentItem;

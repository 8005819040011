import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ComponentsService from '../services/components.service';
import { Component } from '../model/component.model';

export interface ComponentsState {
    components: Component[];
    requestFailed: boolean;
}

const initialState: ComponentsState = {
    components: [],
    requestFailed: false,
};

export const getComponentHealthzStatus = createAsyncThunk('chats/getComponentsHealthzStatus', async () => ComponentsService.getComponentsHealthzStatus());


export const componentsSlice = createSlice({
    name: 'components',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getComponentHealthzStatus.fulfilled, (state, action) => {
            state.components = action.payload.data;
            state.requestFailed = false;
        });
        builder.addCase(getComponentHealthzStatus.rejected, (state) => {
            state.requestFailed = true;
        });
    }
});

export default componentsSlice.reducer;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logo from '../static/buerokratt.svg';

const TopBar = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <TopBarStyles>
            <img className="logo" alt="logo" src={logo} width="40px"/>
            <p className="header">{t('topBar.header')}</p>
        </TopBarStyles>
    );
};

const TopBarStyles = styled.div`
  background-color: #003cff;
  border: 0;
  border-radius: 0;
  min-height: 50px;
  max-height: 50px;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 900;
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    padding: 0.25em;
    margin-left: 1em;
    margin-right: 0.8em;
  }

  .header {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 22px;
    font-weight: lighter;
  }
`;

export default TopBar;

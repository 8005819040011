import http from './http.service';
import { Components } from '../model/components.model';

class ComponentsService {
    getComponentsHealthzStatus(): Promise<Components> {
        return http.post('/cs-get-components-healthz-status');
    }
}

export default new ComponentsService();
